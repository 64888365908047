import Button from '../Button';
import React, { useEffect, useState } from 'react';
import md5 from 'md5';
import { getCookie, setCookie } from '@/components/utils';
import Link from 'next/link';

const CookieConsent = (): JSX.Element => {
  const [showConsent, setShowConsent] = useState(null);

  const handleClick = (): void => {
    const isSet = setCookie('mcookie-token', md5(new Date().getTime().toString()), 60);
    setShowConsent(!isSet);

    document.body.style.overflow = '';
    document.body.style.height = '';
  };

  useEffect(() => {
    const isSet = getCookie('mcookie-token');
    setShowConsent(!isSet);
  }, []);

  if (showConsent === false) return null;

  return (
    <div className="fixed z-[2147483002] bottom-0 left-0 right-0 pointer-events-none top-0 flex justify-center items-end pb-8 px-4">
        <div className="max-w-[700px] bg-white flex gap-8 px-4 py-4 pointer-events-auto justify-center items-center text-xs md:text-sm text-black rounded-md shadow-lg">
          <span>
            This website use{' '}
          <Link href="privacy-policy" passHref legacyBehavior>
              <a className="border-b border-black">cookies</a>
            </Link>{' '}
            to provide the best user experience.
          </span>{' '}
          <Button onClick={handleClick} className="text-sm" theme="primary" noRoute>
            Ok
          </Button>
      </div>
    </div>
  );
};

export default CookieConsent;
