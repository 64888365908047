import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import Icon from '../Icon';
import Modal from 'react-modal';
const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,1)',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0',
  },
};

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  title?: string;
  theme?: string;
  link?: string;
  _key?: string;
  embed?: string;
  route?: string;
  id?: string;
  icon?: string;
  size?: any;
  children?: any;
  noButton?: boolean;
  noRoute?: boolean;
  className?: string;
}

interface ModalProps {
  url: string;
  closeCallback: any;
}

const RenderVideo = ({ url, closeCallback }: ModalProps): JSX.Element => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  let src: string | null = null;

  if (url.includes('vimeo')) {
    const splitArray = url.split('/');
    const index = splitArray.findIndex((v) => v.includes('vimeo.com')) + 1;
    const videoId = splitArray[index];

    if (parseInt(videoId)) {
      src = `https://player.vimeo.com/video/${videoId}?h=e00faf987a&color=ffffff&title=0&portrait=0&autoplay=1`;
    }
  }

  if (!src) return null;

  closeCallback = typeof closeCallback == 'function' ? closeCallback : null;

  return (
    <>
      <Button theme="whiteOutline" onClick={closeCallback} noRoute>
        Close
      </Button>
      <div className="w-full">
        <iframe
          title="embedded content"
          width="100%"
          className="aspect-video w-full h-full rounded-lg"
          allowFullScreen
          allow="autoplay"
          src={src}></iframe>
      </div>
    </>
  );
};

const getClassByTheme = (theme: string): string => {
  const baseClass =
    'gap-2 rounded-md flex flex-cols justify-center items-center cursor-pointer transition-all font-heading font-medium ';

  switch (theme) {
    case 'primary':
      return baseClass + 'bg-primary text-black hover:bg-primaryHover';
    case 'secondary':
      return baseClass + 'bg-primaryHover text-black hover:bg-primary';
    case 'white':
      return baseClass + 'bg-white text-black hover:bg-primaryHover';
    case 'black':
      return baseClass + 'bg-black text-white hover:bg-grey';
    case 'whiteOutline':
      return (
        baseClass +
        'border-[1px] border-solid border-white text-white hover:bg-white hover:text-black'
      );
    case 'blackOutline':
      return (
        baseClass +
        'border-[1px] border-solid border-black text-black hover:bg-black hover:text-white'
      );
    case 'link':
      return 'flex flex-cols gap-2 items-center';
    default:
      return 'flex flex-cols gap-2 items-center';
  }
};

const getClassBySize = (size: string, theme: string): string => {
  if (theme == 'link') return null;

  switch (size) {
    case 'large':
      return 'h-14 px-6';
    case 'medium':
      return 'h-10 px-4';
    default:
      return 'h-8 px-3';
  }
};

const Button = ({
  title,
  theme,
  link,
  _key,
  id,
  embed,
  route,
  icon,
  children,
  noRoute,
  noButton,
  className,
  onClick,
  size = 'medium',
}: ButtonProps): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);

  if (noRoute == true || noRoute !== undefined) {
    if (noButton == false || noButton == undefined) {
      return (
        <button
          onClick={onClick}
          id={id}
          onKeyDown={(e) => e.key == 'Enter' && onClick}
          key={_key}
          className={`${getClassByTheme(theme)} ${getClassBySize(size, theme)} ${className}`}>
          <Icon type={icon} className="w-6" />
          {(children || title) && (
            <span
              className={
                theme == 'link'
                  ? 'border-b-2 border-current whitespace-nowrap'
                  : 'whitespace-nowrap'
              }>
              {children || title}
            </span>
          )}
        </button>
      );
    }

    return (
      <span
        key={_key}
        id={id}
        className={`${getClassByTheme(theme)} ${getClassBySize(size, theme)} ${className}`}>
        <Icon type={icon} className="w-6" />
        {(children || title) && (
          <span
            className={
              theme == 'link' ? 'border-b-2 border-current whitespace-nowrap' : 'whitespace-nowrap'
            }>
            {children || title}
          </span>
        )}
      </span>
    );
  }

  if (route !== undefined && typeof route == 'string') {
    return (
      <Link key={_key} href={'/' + route} passHref legacyBehavior>
        <a
          id={id}
          className={`${getClassByTheme(theme)} ${getClassBySize(size, theme)} ${className}`}>
          <Icon type={icon} className="w-6" />
          {(children || title) && (
            <span
              className={
                theme == 'link'
                  ? 'border-b-2 border-current whitespace-nowrap'
                  : 'whitespace-nowrap'
              }>
              {children || title}
            </span>
          )}
        </a>
      </Link>
    );
  }

  if (link !== undefined) {
    return (
      <a
        key={_key}
        id={id}
        href={link}
        target="_blank"
        rel="noreferrer noopener"
        className={`${getClassByTheme(theme)} ${getClassBySize(size, theme)} ${className}`}>
        <Icon type={icon} className="w-6" />
        {(children || title) && (
          <span
            className={
              theme == 'link' ? 'border-b-2 border-current whitespace-nowrap' : 'whitespace-nowrap'
            }>
            {children || title}
          </span>
        )}
      </a>
    );
  }

  if (embed) {
    return (
      <>
        <button
          key={_key}
          id={id}
          onClick={() => {
            setModalOpen(true);
          }}
          className={`${getClassByTheme(theme)} ${getClassBySize(size, theme)} ${className}`}>
          <Icon type={icon} className="w-6" />
          {(children || title) && (
            <span
              className={
                theme == 'link'
                  ? 'border-b-2 border-current whitespace-nowrap'
                  : 'whitespace-nowrap'
              }>
              {children || title}
            </span>
          )}
        </button>
        {modalOpen ? (
          <Modal
            style={modalStyle}
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            className="flex flex-col gap-4 justify-center items-center w-full max-w-[1280px] px-8">
            <RenderVideo url={embed} closeCallback={() => setModalOpen(false)} />
          </Modal>
        ) : null}
      </>
    );
  }

  return null;
};

export default Button;
