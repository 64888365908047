import '../styles/tailwind.css';
import '../styles/global.scss';
import 'swiper/scss';
import 'swiper/scss/navigation';

// import App from 'next/app';
import Head from 'next/head';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { polyfill } from 'seamless-scroll-polyfill';

const tagManagerArgs = {
  gtmId: 'GTM-NG87GR5',
};

import Preview from '@/components/bits/Preview'; /* webpackPreload: true */
import CookieConsent from '@/components/common/CookieConsent';

require('intersection-observer');

interface Props {
  Component: any;
  pageProps: {
    preview: boolean;
  };
}

function Sanity({ Component, pageProps, pageProps: { preview } }: Props): JSX.Element {
  useEffect(() => {
    polyfill();
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div id="_app">
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <script type="text/javascript" src="https://cdn.weglot.com/weglot.min.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          Weglot.initialize({
            api_key: 'wg_a76eb334701af249114a1dcfac0311d33',
            switchers: [
              {
                button_style: {
                    full_name: false,
                    with_name: false,
                    is_dropdown: true,
                    with_flags: true,
                    flag_type: "circle"
                },
                location: {
                    target: ".language-switcher",
                }
              }
            ]
          });
          `,
          }}
        />
      </Head>
      {preview && <Preview />}

      <Component {...pageProps} />
      <CookieConsent />
    </div>
  );
}

export default Sanity;
