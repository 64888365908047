import React from 'react';

const Preview: React.FunctionComponent = (): JSX.Element => (
  <div className="p-1 text-white text-center inline-flex items-center justify-center fixed bottom-1 right-1 z-10">
    <a className="no-underline" href="/api/exit-preview">
      Exit Preview
    </a>
  </div>
);

export default Preview;
