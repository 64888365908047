import React from "react";

const Icon = ({ type, className = '' }: {
  type: string;
  className?: string;
}): JSX.Element => {
  const mergedClass = `shrink-0 ${className}`;

  switch (type) {
    case 'externalarrow':
      return (
        <svg className={mergedClass} width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.66797 22.3438C3.44141 22.3438 2.51953 22.0391 1.90234 21.4297C1.29297 20.8281 0.988281 19.9219 0.988281 18.7109V4.40234C0.988281 3.19141 1.29297 2.28516 1.90234 1.68359C2.51953 1.07422 3.44141 0.769531 4.66797 0.769531H18.8828C20.1094 0.769531 21.0273 1.07422 21.6367 1.68359C22.2539 2.28516 22.5625 3.19141 22.5625 4.40234V18.7109C22.5625 19.9219 22.2539 20.8281 21.6367 21.4297C21.0273 22.0391 20.1094 22.3438 18.8828 22.3438H4.66797ZM4.69141 20.457H18.8594C19.4375 20.457 19.8828 20.3047 20.1953 20C20.5156 19.6875 20.6758 19.2266 20.6758 18.6172V4.49609C20.6758 3.88672 20.5156 3.42969 20.1953 3.125C19.8828 2.8125 19.4375 2.65625 18.8594 2.65625H4.69141C4.10547 2.65625 3.65625 2.8125 3.34375 3.125C3.03125 3.42969 2.875 3.88672 2.875 4.49609V18.6172C2.875 19.2266 3.03125 19.6875 3.34375 20C3.65625 20.3047 4.10547 20.457 4.69141 20.457ZM15.4609 14.4336C15.2031 14.4336 14.9922 14.3516 14.8281 14.1875C14.6719 14.0234 14.5938 13.8008 14.5938 13.5195V11.4922L14.7695 9.67578L13.0938 11.4688L8.76953 15.793C8.58984 15.9727 8.36719 16.0625 8.10156 16.0625C7.84375 16.0625 7.63672 15.9844 7.48047 15.8281C7.32422 15.6641 7.24609 15.4492 7.24609 15.1836C7.24609 14.957 7.33984 14.75 7.52734 14.5625L11.8516 10.2383L13.668 8.53906L11.9336 8.72656H9.80078C9.51953 8.72656 9.29297 8.64844 9.12109 8.49219C8.95703 8.33594 8.875 8.125 8.875 7.85938C8.875 7.60156 8.95703 7.39453 9.12109 7.23828C9.28516 7.08203 9.50391 7.00391 9.77734 7.00391H15.3438C15.6406 7.00391 15.875 7.07812 16.0469 7.22656C16.2266 7.375 16.3164 7.625 16.3164 7.97656V13.4961C16.3164 13.7695 16.2383 13.9961 16.082 14.1758C15.9258 14.3477 15.7188 14.4336 15.4609 14.4336Z" fill="currentColor" />
        </svg>

      )
    case 'facebook':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21 11.0001H19C18.2044 11.0001 17.4413 11.3162 16.8787 11.8788C16.3161 12.4414 16 13.2045 16 14.0001V28.0001"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 18.0001H20"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'linkedin':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M26 5H6C5.44772 5 5 5.44772 5 6V26C5 26.5523 5.44772 27 6 27H26C26.5523 27 27 26.5523 27 26V6C27 5.44772 26.5523 5 26 5Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 14.0001V22.0001"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 14.0001V22.0001"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 17.5001C15 16.5719 15.3687 15.6816 16.0251 15.0252C16.6815 14.3689 17.5717 14.0001 18.5 14.0001C19.4283 14.0001 20.3185 14.3689 20.9749 15.0252C21.6313 15.6816 22 16.5719 22 17.5001V22.0001"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 11C11.5523 11 12 10.5523 12 10C12 9.44772 11.5523 9 11 9C10.4477 9 10 9.44772 10 10C10 10.5523 10.4477 11 11 11Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'instagram':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.5 4.5H10.5C7.18629 4.5 4.5 7.18629 4.5 10.5V21.5C4.5 24.8137 7.18629 27.5 10.5 27.5H21.5C24.8137 27.5 27.5 24.8137 27.5 21.5V10.5C27.5 7.18629 24.8137 4.5 21.5 4.5Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.5 10.5C23.0523 10.5 23.5 10.0523 23.5 9.5C23.5 8.94772 23.0523 8.5 22.5 8.5C21.9477 8.5 21.5 8.94772 21.5 9.5C21.5 10.0523 21.9477 10.5 22.5 10.5Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'warning':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M16 13V18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M14.269 4.99809L3.27198 23.9971C3.09602 24.3011 3.00322 24.6461 3.00293 24.9974C3.00264 25.3486 3.09487 25.6938 3.27033 25.9981C3.44579 26.3024 3.6983 26.5551 4.00246 26.7308C4.30661 26.9065 4.65167 26.999 5.00294 26.999H26.997C27.3483 26.999 27.6933 26.9065 27.9975 26.7308C28.3016 26.5551 28.5541 26.3024 28.7296 25.9981C28.9051 25.6938 28.9973 25.3486 28.997 24.9974C28.9967 24.6461 28.9039 24.3011 28.7279 23.9971L17.7309 4.99809C17.5552 4.69457 17.3028 4.44258 16.999 4.2674C16.6952 4.09222 16.3507 4 16 4C15.6493 4 15.3047 4.09222 15.0009 4.2674C14.6971 4.44258 14.4447 4.69457 14.269 4.99809V4.99809Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 23.5C16.5523 23.5 17 23.0523 17 22.5C17 21.9477 16.5523 21.5 16 21.5C15.4477 21.5 15 21.9477 15 22.5C15 23.0523 15.4477 23.5 16 23.5Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'arrowup':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M16 27V5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M7 14L16 5L25 14"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'arrowleft':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M27 16H5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M14 7L5 16L14 25"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'arrowright':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M5 16H27" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M18 7L27 16L18 25"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'arrowdown':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M16 5V27" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M7 18L16 27L25 18"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'checkmark':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M27 9.00063L13 23L6 16.0006"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'plus':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M5 16H27" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16 5V27" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );
    case 'play':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20 16L14 12V20L20 16Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'noimage':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.5 18.5L21.2928 17.7072C21.3857 17.6143 21.4959 17.5407 21.6172 17.4904C21.7386 17.4402 21.8686 17.4143 21.9999 17.4143C22.1312 17.4143 22.2613 17.4402 22.3826 17.4904C22.5039 17.5407 22.6142 17.6143 22.707 17.7072L27.9999 23.0001"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 21.0001L10.2929 14.7072C10.3857 14.6143 10.496 14.5407 10.6173 14.4904C10.7386 14.4402 10.8687 14.4143 11 14.4143C11.1313 14.4143 11.2613 14.4402 11.3827 14.4904C11.504 14.5407 11.6142 14.6143 11.7071 14.7072L17.2929 20.293"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.5 13.5C20.0523 13.5 20.5 13.0523 20.5 12.5C20.5 11.9477 20.0523 11.5 19.5 11.5C18.9477 11.5 18.5 11.9477 18.5 12.5C18.5 13.0523 18.9477 13.5 19.5 13.5Z"
            fill="currentColor"
          />
          <path
            d="M4.18164 3L27.818 29"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 6H27C27.3333 6 28 6.2 28 7C28 7.8 28 19.3333 28 25C28 25.3333 27.8 26 27 26"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23 26C17.3333 26 5.8 26 5 26C4.2 26 4 25.3333 4 25V7C4 6.2 4.66667 6 5 6"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'close':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M25 7L7 25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M25 25L7 7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );
    case 'checkcircle':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.5 13L14.1666 20L10.5 16.5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'caretdown':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M26 12L16 22L6 12"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'caretup':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6 20L16 10L26 20"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'user':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 20C20.4183 20 24 16.4183 24 12C24 7.58172 20.4183 4 16 4C11.5817 4 8 7.58172 8 12C8 16.4183 11.5817 20 16 20Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.87354 26.9988C5.10299 24.8708 6.8708 23.1037 8.99939 21.8752C11.128 20.6467 13.5424 20 16.0001 20C18.4577 20 20.8721 20.6468 23.0007 21.8754C25.1292 23.1039 26.897 24.871 28.1264 26.9991"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'email':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 7H28V24C28 24.2652 27.8946 24.5196 27.7071 24.7071C27.5196 24.8946 27.2652 25 27 25H5C4.73478 25 4.48043 24.8946 4.29289 24.7071C4.10536 24.5196 4 24.2652 4 24V7Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28 7L16 18L4 7"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'headset':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M28.182 16H24.182C23.6515 16 23.1428 16.2107 22.7678 16.5858C22.3927 16.9609 22.182 17.4696 22.182 18V23C22.182 23.5304 22.3927 24.0391 22.7678 24.4142C23.1428 24.7893 23.6515 25 24.182 25H26.182C26.7124 25 27.2211 24.7893 27.5962 24.4142C27.9713 24.0391 28.182 23.5304 28.182 23V16ZM28.182 16C28.182 14.4165 27.8686 12.8486 27.2598 11.3868C26.651 9.92497 25.759 8.59808 24.635 7.48262C23.5111 6.36715 22.1775 5.48517 20.7111 4.88751C19.2447 4.28986 17.6745 3.98834 16.091 4.00034C14.5075 3.98834 12.9373 4.28986 11.4709 4.88751C10.0045 5.48517 8.6709 6.36715 7.54695 7.48262C6.423 8.59808 5.53093 9.92497 4.92217 11.3868C4.31341 12.8486 4 14.4165 4 16V23C4 23.5304 4.21071 24.0391 4.58579 24.4142C4.96086 24.7893 5.46957 25 6 25H8C8.53043 25 9.03914 24.7893 9.41421 24.4142C9.78929 24.0391 10 23.5304 10 23V18C10 17.4696 9.78929 16.9609 9.41421 16.5858C9.03914 16.2107 8.53043 16 8 16H4"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.182 23V26C28.182 27.0609 27.7605 28.0783 27.0104 28.8284C26.2603 29.5786 25.2428 30 24.182 30H17"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'info':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 15H16V22H17"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 11.5C16.5523 11.5 17 11.0523 17 10.5C17 9.94772 16.5523 9.5 16 9.5C15.4477 9.5 15 9.94772 15 10.5C15 11.0523 15.4477 11.5 16 11.5Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'edit':
      return (
        <svg
          className={mergedClass}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5195 5 26.2652 5 26V20.4142C5 20.2829 5.02587 20.1528 5.07612 20.0315C5.12638 19.9102 5.20004 19.7999 5.29289 19.7071L20.2929 4.70708C20.4804 4.51954 20.7348 4.41418 21 4.41418C21.2652 4.41418 21.5196 4.51954 21.7071 4.70708L27.2929 10.2929C27.4804 10.4804 27.5858 10.7348 27.5858 11C27.5858 11.2652 27.4804 11.5195 27.2929 11.7071L12 27Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 8L24 15"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.0001 27H12.0001L5.06372 20.0636"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return null;
  }
};

export default Icon;
