import { sanityClient } from '@/utils/sanity/sanity.server';
import imageUrlBuilder from '@sanity/image-url';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { ImageUrlBuilder } from 'next-sanity-image';

const builder = imageUrlBuilder(sanityClient);

export const urlFor = (source: SanityImageSource): ImageUrlBuilder => {
  return builder.image(source);
};

export const clamp = (num: number, lowerBound: number, upperBound: number): number =>
  Math.min(Math.max(num, lowerBound), upperBound);

export const parseEquation = (str: string, x: number | string): any => {
  const nString = str.replace('x', x.toString());
  return Function(`'use strict'; return (${nString})`)();
};

export const setCookie = (cname: string, cvalue: string, exdays: number): boolean => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';

  return true;
};

export const getCookie = (cname: string): boolean => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return true;
    }
  }
  return false;
};
